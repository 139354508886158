import React from 'react';
import logo from './pulsr-white.svg';
import './App.css';

function App() {
  return (
    <div className="app">
      <div className="app-container">
        <img src={logo} className="logo" alt="pulsr" />
      </div>
    </div>
  );
}

export default App;
